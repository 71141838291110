.mt{
   margin-top: 20px !important;
}
.BarcodeProcessing-custom .MuiDialog-paper{
   width:200px;
}
.drawingBuffer{
   margin-top:-700px;
}
.BarcodeProcessing-custom  .MuiDialogContent-dividers{
   text-align:center;
}

.ScannIcon {
   display: flex;
    justify-content: center;
    padding-top: 20px
}
.ScannIcon button {
   margin-right: 8px
}


.ScannIcon img {
   width: 30px;
   margin-right: 8px;
}
.ScannIcon span{
   text-transform: uppercase;
}
.BarCodeViewImage{
   width:75%;
   text-align:left;
}
.BArcodeViewImage .PopupSubHeading img{
   width: 75%;
   margin-left: 45px;
   height: auto;
}

.BarCodeViewImage .PopupSubHeading#last_bc_image img{
   width: 100%;
   height: auto;
}

.LogoSection {
   text-align: center;
   padding-top: 34px;
}
#TableCustomPagination {margin: 12px auto;text-align: center;display: table;}


.CustomScanBarPopup{
   display: flex;
   justify-content: space-between;
}
.CustomScanBarPopup .MuiButton-label{
   color:#fff;
   margin-right:-11px;
}

   .BarCodePopupCustom {
      padding: 0px 30px;
      width: 50%;
       float: left;
   }
   .BarcodeMode{
      width: 18%;
      float: right;
      height:auto;
   }
   
   @media only screen and (min-width: 400px) {
      .BarcodeMode{
         width: 40%;
         
      }
   }
.BarCodePopupCustom .PopupHeading {
margin-bottom:5px;
}
.BarCodePopupCustom .PopupSubHeading {
   margin: 0;
   line-height: 2;
   z-index: 2;
}
.ScanCustom {
   text-align: center;
   position: fixed;
   top:500px;
   left: 0px;
   /* bottom: 0px; */
   height: 163px;
   width: 100%;
   z-index: 3;
  
}
.PopupHeading {
   margin: 0;
}
.CustomSelect .MuiInputBase-formControl {
   border: 1px solid #949494;
}
.ScanCustom .MuiButton-outlinedPrimary {
   width: 65px;
   background: #5f5d5d;
   height: 65px;
   border-radius: 100px !important;
   margin: 20px 0px;
   border: 2px solid black;
}
.ScanCustom .MuiButton-outlinedPrimary:hover{
   background: #000000;}

   @media screen and (max-width: 400px) {
      div.forceScroll {
         overflow-y: scroll
      }
   }
   